import React from 'react'
import classNames from 'classnames'
import DefaultLayout from '../layout/default'
import HeadingAndTextarea from '../components/_V3/HeadingAndTextarea'
import CardFullWidth from '../components/_V3/CardFullWidth'
import CardListHalfWidth from '../components/_V3/CardListHalfWidth'

import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'

import Pres from '../pagesSections/index/ProductPresentation/assets/pres.png'
import PresKz from '../pagesSections/index/ProductPresentation/assets/pres_kz.png'
import PresBy from '../pagesSections/index/ProductPresentation/assets/pres_by.png'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/discounts.module.scss'

import { getHeadingAndTextarea } from '../pages-data/_V3/discounts/HeadingAndTextarea/data'
import { getData as cardFullWidth } from '../pages-data/_V3/discounts/CardFullWidth/data'
import { getData as cardListHalfWidth } from '../pages-data/_V3/discounts/CardListHalfWidth/data'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageDiscounts() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Акции Quick Resto: бонусные программы и спецпредложения',
    'ru-KZ': 'Акции Quick Resto Казахстан: бонусные программы и спецпредложения',
    'kz-KZ': 'Акции Quick Resto Казахстан: бонусные программы и спецпредложения',
    'ru-BY': 'Акции Quick Resto в Беларуси: бонусные программы и спецпредложения',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}discounts/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}discounts/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}discounts/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}discounts/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Акционные предложения Quick Resto: подключите систему автоматизации на выгодных условиях и зарабатывайте бонусные баллы',
    'ru-KZ': 'Акционные предложения Quick Resto в Казахстане: подключите систему автоматизации на выгодных условиях и зарабатывайте бонусные баллы',
    'kz-KZ': 'Акционные предложения Quick Resto в Казахстане: подключите систему автоматизации на выгодных условиях и зарабатывайте бонусные баллы',
    'ru-BY': 'Акционные предложения Quick Resto в Беларуси: подключи систему автоматизации на выгодных условиях и зарабатывай бонусные баллы',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Pres}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale],
    },
  ]

  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.discountsPage,
      )}
      linkCanonical={currentCanonicalLink}
    >
      <HeadingAndTextarea
        className={styles.discounts__headingAndTextarea}
        data={getHeadingAndTextarea(localizationContext.locale)}
      />

      <CardListHalfWidth
        data={cardListHalfWidth(localizationContext.locale)}
        className={classNames(
				  pageStyles.pageSection,
				  styles.discounts__cardListHalfWidth,
        )}
      />

      {localizationContext.locale === 'ru-RU' && (
      <CardFullWidth
        data={cardFullWidth(localizationContext.locale)}
        showBackdrop={false}
        className={classNames(
          pageStyles.pageSection,
          styles.discounts__cardFullWidth,
        )}
      />
      )}

      <CtaForm isWhiteBackground className={pageStyles.pageSection} />

      <KnowledgeBase
        theme="white"
        type="horizontal"
        className={classNames(
				  pageStyles.pageSection,
				  styles.discounts__knowledgeBase,
        )}
      />
    </DefaultLayout>
  )
}
