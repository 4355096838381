import React from "react"
import classNames from "classnames"

import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			heading: "Реферальная программа",
			textarea: (
				<span className={styles.textarea}>
			Мы&nbsp;уверены, что рестораторы дружный народ и&nbsp;всегда готовы
			порекомендовать коллегам по&nbsp;цеху сервисы, которыми пользуются сами.
			Предлагаем специальные тарифные планы для участников программы.
		</span>
			),
			btn: {
				text: "Подробнее",
				url: pagesLinks.discounts.href,
				isButton: true,
			},
			mediaContent: (
				<>
					<StaticImage
						className={classNames(styles.image1, styles.contentDesktop)}
						src="./assets/discounts-full-image1-d.png"
						alt="Приведи друга 2.0"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(styles.image1, styles.contentTablet)}
						src="./assets/discounts-full-image1-t.png"
						alt="Приведи друга 2.0"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.contentMobile}
						src="./assets/discounts-full-image1-m.png"
						alt="Приведи друга 2.0"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
			popup: {
				heading: "Реферальная программа",
				textarea:
					"Новая версия нашей постоянной акции: рекомендуй нас коллегам, получай скидки или другие бонусы",
				btn: {
					text: "Подключить",
					isButton: true,
				},
				mediaContent: (
					<StaticImage
						src="./assets/discounts-full-image1-modal.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				content: (
					<>
				<span className={styles.popup__text}>
					Мы&nbsp;благодарим за&nbsp;рекомендацию сервиса Quick Resto.
					За&nbsp;каждого друга, который становится нашим клиентом, тебе будут
					доступны бонусы на выбор:
				</span>

						<ul className={styles.popup__ul}>
							<li>
								<span>Скидка 35 Р/мес. для любой подписки Quick Resto</span>
							</li>
							<li>
								<span>Бесплатный апгрейд до тарифа PRO на один месяц</span>
							</li>
							<li>
								<span>Бесплатная лицензия для дополнительного терминала</span>
							</li>
						</ul>

						<h3 className={styles.popup__heading}>
							Как воспользоваться предложением
						</h3>
						<p className={styles.popup__text}>
							Приглашённый тобой пользователь должен назвать менеджеру имя твоего
							облака или другие данные для&nbsp;идентификации. Остальное&nbsp;&mdash;
							за&nbsp;нами. Предложение работает весь период, пока приглашенные
							пользователи остаются нашими действующими клиентами.
						</p>
					</>
				),
			},
		}
	}

	if (locale === 'ru-KZ') {
		return {
			heading: "Реферальная программа",
			textarea: (
				<span className={styles.textarea}>
			Мы&nbsp;уверены, что рестораторы дружный народ и&nbsp;всегда готовы
			порекомендовать коллегам по&nbsp;цеху сервисы, которыми пользуются сами.
			Предлагаем специальные тарифные планы для участников программы.
		</span>
			),
			btn: {
				text: "Подробнее",
				url: pagesLinks.discounts.href,
				isButton: true,
			},
			mediaContent: (
				<>
					<StaticImage
						className={classNames(styles.image1, styles.contentDesktop)}
						src="./assets/discounts-full-image1-d.png"
						alt="Реферальная программа"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(styles.image1, styles.contentTablet)}
						src="./assets/discounts-full-image1-t.png"
						alt="Реферальная программа"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.contentMobile}
						src="./assets/discounts-full-image1-m.png"
						alt="Реферальная программа"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
			popup: {
				heading: "Реферальная программа",
				textarea:
					"Новая версия нашей постоянной акции: рекомендуй нас коллегам, получай скидки или другие бонусы",
				btn: {
					text: "Подключить",
					isButton: true,
				},
				mediaContent: (
					<StaticImage
						src="./assets/discounts-full-image1-modal.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				content: (
					<>
				<span className={styles.popup__text}>
					Мы&nbsp;благодарим за&nbsp;рекомендацию сервиса Quick Resto.
					За&nbsp;каждого друга, который становится нашим клиентом, тебе будут
					доступны бонусы на выбор:
				</span>

						<ul className={styles.popup__ul}>
							<li>
								<span>Скидка 5000 ₸/мес. для любой подписки Quick Resto</span>
							</li>
							<li>
								<span>Бесплатный апгрейд до тарифа PRO на один месяц</span>
							</li>
							<li>
								<span>Бесплатная лицензия для дополнительного терминала</span>
							</li>
						</ul>

						<h3 className={styles.popup__heading}>
							Как воспользоваться предложением
						</h3>
						<p className={styles.popup__text}>
							Приглашённый тобой пользователь должен назвать менеджеру имя твоего
							облака или другие данные для идентификации. Остальное&nbsp;&mdash;
							за&nbsp;нами. Предложение работает весь период, пока приглашенные
							пользователи остаются нашими действующими клиентами.
						</p>
					</>
				),
			},
		}
	}

	return {
		heading: "Промокод на рекламу",
		textarea: (
			<span className={styles.textarea}>
			Получи промокод на 6 000 ₽ к рекламному бюджету и месяц
				бесплатно на максимальном тарифе Pro. Яндекс Бизнес поможет найти
				ваших клиентов, а Quick Resto — организовать работу вашего заведения.
		</span>
		),
		btn: {
			text: "Подробнее",
			url: pagesLinks.discounts.href,
			isButton: true,
		},
		mediaContent: (
			<>
				<StaticImage
					className={classNames(styles.image1, styles.contentDesktop)}
					src="./assets/discounts-full-image2-d.png"
					alt="Интеграция Quick Resto x Яндекс Бизнес"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					className={classNames(styles.image1, styles.contentTablet)}
					src="./assets/discounts-full-image2-t.png"
					alt="Интеграция Quick Resto x Яндекс Бизнес"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					className={styles.contentMobile}
					src="./assets/discounts-full-image2-m.png"
					alt="Интеграция Quick Resto x Яндекс Бизнес"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</>
		),
		popup: {
			heading: "Промокод на рекламу",
			textarea:
				"Яндекс Бизнес поможет найти ваших клиентов, а Quick Resto —  организовать работу кафе, ресторана или бара",
			btn: {
				text: "Подключить",
				isButton: true,
			},
			mediaContent: (
				<StaticImage
					src="./assets/discounts-full-image2-modal.png"
					alt="Интеграция Quick Resto x Яндекс Бизнес"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			content: (
				<>
				{/*<span className={styles.popup__text}>*/}
				{/*	Мы&nbsp;благодарим за&nbsp;рекомендацию сервиса Quick Resto.*/}
				{/*	За&nbsp;каждого друга, который становится нашим клиентом, тебе будут*/}
				{/*	доступны бонусы на выбор:*/}
				{/*</span>*/}

				{/*	<ul className={styles.popup__ul}>*/}
				{/*		<li>*/}
				{/*			<span>Скидка 1000 ₽/мес. для любой подписки Quick Resto</span>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<span>Бесплатный апгрейд до тарифа PRO на один месяц</span>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<span>Бесплатная лицензия для дополнительного терминала</span>*/}
				{/*		</li>*/}
				{/*	</ul>*/}

					<h3 className={styles.popup__heading}>
						Условия акции:
					</h3>
					<div className={styles.popup__conditions}>
						<article className={styles.popup__condition}>
							<h4 className={styles.popup__condition__heading}>
								Условия получения промокода <br />
								от Яндекс Бизнеса
							</h4>
							<p className={styles.popup__text}>
								Предложение действует для новых клиентов <br />
								Яндекс Бизнеса при минимальном бюджете <br />
								рекламной кампании от 15 000 ₽ и сроке <br />
								размещения от 90 дней
							</p>
						</article>
						<article className={styles.popup__condition}>
							<h4 className={styles.popup__condition__heading}>
								Условия акции <br />
								Quick Resto
							</h4>
							<p className={styles.popup__text}>
								Предложение действует для новых пользователей сервиса
							</p>
						</article>
					</div>
					{/*<p className={styles.popup__text}>*/}
					{/*	Приглашённый тобой пользователь должен назвать менеджеру имя твоего*/}
					{/*	облака или другие данные для идентификации. Остальное&nbsp;&mdash;*/}
					{/*	за&nbsp;нами. Предложение работает весь период, пока приглашенные*/}
					{/*	пользователи остаются нашими действующими клиентами.*/}
					{/*</p>*/}
				</>
			),
		},
	}
}
